<template>
  <div class="relative bg-white overflow-hidden">
    <div class="max-w-screen-xl mx-auto ">
      <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
        <svg class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none">
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>

        <div class="relative pt-6 px-4 sm:px-6 lg:px-8">
          <nav class="relative flex items-center justify-between sm:h-10 lg:justify-start">
            <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
              <div class="flex items-center justify-between w-full md:w-auto">
                <a href="#">
                  <img class="h-16 pt-1 w-auto sm:h-12" src="../assets/img/logo2.png" alt="" />
                </a>
                <div class="-mr-2 flex items-center md:hidden">
                  <button @click="openMenu()" type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                    <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="hidden md:block md:ml-4 md:pr-4">
              <div class="dropdown inline-block relative">
                <button id="dropdown" class="dropdown font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">{{ $t('nav.web') }}</button>
                <ul class=" absolute hidden dropdown-menu text-gray-700 pt-1 text-black">
                  <li class=""><router-link to="/web" class="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="#">{{ $t('nav.hosting') }}</router-link></li>
                  <li class=""><router-link to="/tld" class="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="#">{{ $t('nav.domain') }}</router-link></li>
                  <li class=""><router-link to="/mails" class="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="#">{{ $t('nav.mail') }}</router-link></li>
                </ul>
              </div>

              <router-link to="/vps" class="ml-8 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">{{ $t('nav.vps') }}</router-link>
              <router-link to="/vpn" class="ml-8 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">{{ $t('nav.vpn') }}</router-link>
              <a href="https://manager.hydrosaas.com" target="_blank" class="ml-8 py-3 px-3 bg-hydro shadow hover:bg-hydroactive text-white  font-medium rounded-full hover:text-white focus:outline-none transition duration-150 ease-in-out">{{ $t('nav.client') }}</a>
            </div>
          </nav>
        </div>

        <div class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden" :class="menu ? 'show' : 'hidden'">
          <div class="rounded-lg shadow-md">
            <div class="rounded-lg bg-white shadow-xs overflow-hidden">
              <div class="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img class="h-8 w-auto" src="../assets/img/logo2.png" alt="" />
                </div>
                <div class="-mr-2">
                  <button type="button"  class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" @click="openMenu()">
                    <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
              <div class="px-2 pt-2 pb-3" >
                <router-link to="/web" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">{{ $t('nav.web') }}</router-link>
                <router-link to="/mails" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">{{ $t('nav.mail') }}</router-link>
                <router-link to="/vps" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">{{ $t('nav.vps') }}</router-link>
                <router-link to="/vpn" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">{{ $t('nav.vpn') }}</router-link>
                <a href="https://manager.hydrosaas.com" target="_blank" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">{{ $t('nav.client') }}</a>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
          <div class="sm:text-center lg:text-left">
            <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
              {{ $t('hero.partner') }}
              <br class="xl:hidden" />
              <vue-typed-js :strings="['VPS', 'TLDs', 'VPN', 'Hebergement Web']" :loop="true" :backDelay="1000">
                <span class="text-hydro typing"></span>
              </vue-typed-js>
            </h2>
            <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              {{ $t('hero.headline') }}
            </p>
            <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div class="rounded-md shadow">
                <a href="#about" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-hydro hover:bg-hydroactive focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                  {{ $t('hero.more') }}
                </a>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-3">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="https://images.unsplash.com/photo-1586772002345-339f8042a777?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80" alt="" />
    </div>
  </div>
</template>

<style type="text/css">
  .dropdown:hover .dropdown-menu {
    display: block;
  }
</style>

<script>
export default {
  name: 'Hero',
  props: {
    msg: String
  },
  data: function () {
    return {
      menu: false,
    }
  },
  methods: {
    openMenu() {
      if (this.menu) {
        this.menu = false;
      } else {
        this.menu = true;
      }
    }
  },
  mounted() {
    this.menu = false;
  }
}
</script>
