<template>
	<div>
		<Hero />
		<!--
		<script type="application/ld+json">
			{
				"@context": "https://schema.org",
				"@type": "Organization",
				"name": "HydroSaaS",
				"alternateName": "HydroChain SAS",
				"url": "https://hydrosaas.com/",
				"logo": "https://hydrosaas.com/img/logo2.b47fa802.png",
				"sameAs": [
					"https://twitter.com/hydrosaas/",
					"https://www.linkedin.com/company/hydrosaas/"
				]
			}
		</script>
		-->
		<div class="py-12 bg-white">
			<div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
				<div class="lg:text-center" id="about">
					<p class="text-base leading-6 text-hydro font-semibold tracking-wide uppercase">{{ $t('home.security') }} </p>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
						{{ $t('home.why') }}
					</h3>
					<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-700 lg:mx-auto">
						{{ $t('home.security_about') }}
					</p>
				</div>
				<div class="mt-10" >
					<ul class="md:grid md:grid-cols-3 md:col-gap-8 md:row-gap-10">
						<li>
							<div class="flex-shrink-0">
									<img src="../assets/img/chat.svg" class="m-auto h-32" alt="chat">
							</div>
							<div class="ml-4 text-center">
								<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('home.support') }}</h5>
								<p class="mt-2 text-base leading-6 text-gray-500">
									{{ $t('home.support_about') }}
								</p>
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/security.svg" class="m-auto h-32" alt="chat">
							</div>
							<div class="ml-4 text-center">
								<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('home.ddos')}}</h5>
								<p class="mt-2 text-base leading-6 text-gray-500">
									{{ $t('home.ddos_about') }}
								</p>
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/server.svg" class="m-auto h-32" alt="chat">
							</div>
							<div class="ml-4 text-center">
								<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('home.infra') }}</h5>
								<p class="mt-2 text-base leading-6 text-gray-500">
									{{ $t('home.infra_about') }}
								</p>
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/backup.svg" class="m-auto h-32" alt="chat">
							</div>
							<div class="ml-4 text-center">
								<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('home.backup') }}</h5>
								<p class="mt-2 text-base leading-6 text-gray-500">
									{{ $t('home.backup_about') }}
								</p>
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/open_source.svg" class="m-auto h-32" alt="chat">
							</div>
							<div class="ml-4 text-center">
								<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('home.open_source') }}</h5>
								<p class="mt-2 text-base leading-6 text-gray-500">
									{{ $t('home.open_source_about') }}
								</p>
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/growing.svg" class="m-auto h-32" alt="chat">
							</div>
							<div class="ml-4 text-center">
								<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('home.evolution') }}</h5>
								<p class="mt-2 text-base leading-6 text-gray-500">
									{{ $t('home.evolution_about') }}
								</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<!--<div class="py-12 bg-white">
			<div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
				<div class="lg:text-center">
					<p class="text-base leading-6 text-hydro font-semibold tracking-wide uppercase"> Nos Technologies </p>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
						Voici les technologies que nous utilisons
					</h3>
					<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
						HydroSaaS vous procure des outils à la pointe de la technologie, tout en s’assurant de la stabilité et de la sécurité des services proposés.
					</p>
				</div>
				<div class="mt-10">
					<ul class="md:grid md:grid-cols-3 md:col-gap-8 md:row-gap-10">
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/docker.svg" class="m-auto h-20" alt="chat">
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/proxmox.png" class="m-auto h-20" alt="chat">
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/ansible.png" class="m-auto h-20" alt="chat">
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/vesta.png" class="m-auto h-20" alt="chat">
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/sonar.png" class="m-auto h-20" alt="chat">
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/ubuntu.png" class="m-auto h-20" alt="chat">
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>-->

		<div class="bg-gray-50">
			<div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:items-center lg:justify-between">
				<div class="lg:text-center">
					<p class="text-base leading-6 text-hydro font-semibold tracking-wide uppercase">{{ $t('home.domain_name') }}</p>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
						{{ $t('home.domain_name_headline') }}
					</h3>
					<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
						{{ $t('home.domain_name_about') }}
					</p>
					<form class="w-full">
						<form action="https://manager.hydrosaas.com/cart.php?a=add&domain=register" method="post" class="flex m-auto pt-4 max-w-md items-center border-b border-b-2 border-hydro py-2">
							<input name="query" class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="example.com" aria-label="domain name">
							<button type="submit" class="flex-shrink-0 bg-hydro hover:bg-hydroactive border-hydro hover:border-hydroactive text-sm border-4 text-white py-1 px-2 rounded">{{ $t('home.order') }}</button>
						</form>
					</form>
				</div>
			</div>
		</div>

		<div class="py-12 bg-white">
			<div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
				<div class="lg:text-center">
					<p class="text-base leading-6 text-hydro font-semibold tracking-wide uppercase">{{ $t('home.payment') }}</p>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
						{{ $t('home.payment_headline') }}
					</h3>
					<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
						{{ $t('home.payment_about') }}
					</p>
					<div class="flex container mt-4">
						<span class="m-auto">
							<img class="h-10" src="../assets/img/cb.png" alt="Credit Card">
							<img class="h-10" src="../assets/img/sepa.png"  alt="Sepa" >
						</span>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
	import Hero from '@/components/Hero.vue'
	import Footer from "@/components/Footer";
	import axios from 'axios';
	export default {
		name: 'Home',
		metaInfo: () => ({
			title: 'HydroSaaS',
			titleTemplate: '%s | Hébergement web, VPN et infrastructure cloud VPS',
			meta: [
				{ name: 'description', content: 'Hébergement web de qualité, VPS haute disponibilité, service VPN et infrastructures cloud performantes. Nos compétences au service de votre projet.' },
				{ name: 'og:title', content: 'HydroSaaS | Hébergement web, VPN et infrastructure cloud VPS' },
				{ name: 'twitter:title', content: 'HydroSaaS | Hébergement web, VPS et VPN' },
				{ name: 'og:description', content: 'Hébergement web de qualité, VPS haute disponibilité, service VPN et infrastructures cloud performantes. Nos compétences au service de votre projet.' },
				{ name: 'twitter:description', content: 'Hébergement web de qualité, VPS haute disponibilité, service VPN et infrastructures cloud performantes. Nos compétences au service de votre projet.' },
                { name: 'twitter:image', content: 'https://hydrosaas.com/default.png' },
				{ name: 'og:image', content: 'https://hydrosaas.com/default.png' },
            ],
		}),
		htmlAttrs: {
			amp: true
		},
		components: {
			Footer,
			Hero,
		},
      data () {
        return {
          domain: ''
        }
      },
		methods: {
			checkDomain: function() {
				axios.post('https://manager.hydrosaas.com/includes/api.php', {action: 'DomainWhois', username: 'WW9Fyq5iQbuAK9HzFmTx8Wcukus1pG8o', password: 'WW9Fyq5iQbuAK9HzFmTx8Wcukus1pG8o', domain: this.domain, responsetype: 'json'})
				.then(response => {
					alert(response)
				})
			}
		}
	}
</script>
